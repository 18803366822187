import {
  mandatory,
  nonMandatory,
} from '../../../storeFromRequestParamsValidation';
import { createRegistration } from '../../../initialStateRegistration.utils';
import { Data } from '../../../initialStateRegistration';
import {
  HomePageMinorSection,
  HreflangItem,
  Image,
  TopSectionWithoutSubtitle,
} from '../../template.state.types';
import { createImage } from '../../../articles.utils';
import { SECTION_NAMES } from '../../../../templates/templates.const';
import {
  createHomePageMinorSectionState,
  createHreflangData,
  createTopSectionWithoutSubtitleState,
} from '../../template.utils';

export type HomePageFlorenceTopSections =
  | typeof SECTION_NAMES.TOP_SECTION
  | typeof SECTION_NAMES.SECTION1;
export type HomePageFlorenceMinorSections =
  | typeof SECTION_NAMES.SECTION2
  | typeof SECTION_NAMES.SECTION3
  | typeof SECTION_NAMES.SECTION4
  | typeof SECTION_NAMES.SECTION5
  | typeof SECTION_NAMES.SECTION6
  | typeof SECTION_NAMES.SECTION7
  | typeof SECTION_NAMES.SECTION8
  | typeof SECTION_NAMES.SECTION9
  | typeof SECTION_NAMES.SECTION10
  | typeof SECTION_NAMES.SECTION11
  | typeof SECTION_NAMES.SECTION12;

export type SectionNames =
  | HomePageFlorenceTopSections
  | HomePageFlorenceMinorSections;

export interface HomePageFlorenceState {
  [SECTION_NAMES.TOP_SECTION]: TopSectionWithoutSubtitle;
  [SECTION_NAMES.SECTION1]: TopSectionWithoutSubtitle;
  [SECTION_NAMES.SECTION2]: HomePageMinorSection;
  [SECTION_NAMES.SECTION3]: HomePageMinorSection;
  [SECTION_NAMES.SECTION4]: HomePageMinorSection;
  [SECTION_NAMES.SECTION5]: HomePageMinorSection;
  [SECTION_NAMES.SECTION6]: HomePageMinorSection;
  [SECTION_NAMES.SECTION7]: HomePageMinorSection;
  [SECTION_NAMES.SECTION8]: HomePageMinorSection;
  [SECTION_NAMES.SECTION9]: HomePageMinorSection;
  [SECTION_NAMES.SECTION10]: HomePageMinorSection;
  [SECTION_NAMES.SECTION11]: HomePageMinorSection;
  [SECTION_NAMES.SECTION12]: HomePageMinorSection;
  metadataDescription: string;
  metadataTitle: string;
  metadataImage: Image;
  hreflangData: HreflangItem[] | null;
}

export const FROM_DYNAMIC_SECTION_INDEX = 2;
export const TO_DYNAMIC_SECTION_INDEX = 11;

export const getHomePageFlorenceInitialState = (
  data: Data,
  exclude: Array<keyof HomePageFlorenceState> = [],
) => {
  const imagesHost = mandatory(data.propertySettings.imagesCDNHost, '');
  const buildDynamicSections = () => {
    const sections = {} as Record<HomePageFlorenceMinorSections, () => HomePageMinorSection>;
    for (let i = FROM_DYNAMIC_SECTION_INDEX; i <= TO_DYNAMIC_SECTION_INDEX; i += 1) {
      const sectionName = `section${i}` as HomePageFlorenceMinorSections;
      sections[sectionName] = () => nonMandatory(
        data,
        createHomePageMinorSectionState(sectionName),
      );
    }
    return sections;
  };
  const homePageTransformations = {
    ...buildDynamicSections(),
    [SECTION_NAMES.TOP_SECTION]: () => mandatory(data, {}, createTopSectionWithoutSubtitleState(SECTION_NAMES.TOP_SECTION)),
    [SECTION_NAMES.SECTION1]: () => mandatory(data, {}, createTopSectionWithoutSubtitleState(SECTION_NAMES.SECTION1)),
    metadataDescription: () => mandatory(data.pageContent.metadata.description.value, ''),
    metadataTitle: () => mandatory(data.pageContent.metadata.title.value, ''),
    metadataImage: () => mandatory(data.pageContent.metadata.image.value, '', createImage(imagesHost)),
    hreflangData: () => nonMandatory(data.pageContent.metadata.alternateUrls.value, createHreflangData),
  };
  return createRegistration(
    homePageTransformations,
    exclude,
  ) as HomePageFlorenceState;
};
