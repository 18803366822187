import { configReducer } from '../../store/config/config.reducer';
import { homePageReducer } from '../../store/template/homePage/homePage.reducer';
import { TemplateRegistration } from '../templateRegistration';
import { AppState } from '../../store/initialStateRegistration';
import {
    GET_PALETTE_DATA,
    INJECT_ACCESSIBILITY_ICON_TO_HEADER,
    INJECT_ADS,
    INJECT_BUTTON_TO_FOOTER_LINKS_SECTION,
    INJECT_BUTTON_TO_MENU,
    INJECT_VIDEO_LOGO,
} from '../../client/clientAPI/events';
import { HomePageFlorenceComponent } from './HomePageFlorenceComponent';
import { announcementReducer } from '../../store/announcement/announcement.reducer';
import { footerReducer } from '../../store/navigations/navigation/footer/footer.reducer';
import { headerReducer } from '../../store/navigations/navigation/header/header.reducer';
import { menuReducer } from '../../store/navigations/navigation/menu/menu.reducer';
import { getAnalyticsCoreParamsForAllPages } from '../utils/getAnalyticsCoreParams';

export const rootReducer = {
    config: configReducer,
    footer: footerReducer,
    header: headerReducer,
    menu: menuReducer,
    template: homePageReducer,
    siteAnnouncement: announcementReducer,
};

const getCoreParams = (state: AppState) => ({
    ...getAnalyticsCoreParamsForAllPages(state),
});

export const HomePageFlorence = new TemplateRegistration()
    .setComponent(HomePageFlorenceComponent)
    .setRootReducer(rootReducer)
    .setCoreParams(getCoreParams)
    .addClientAPIEvent(INJECT_VIDEO_LOGO)
    .addClientAPIEvent(INJECT_BUTTON_TO_FOOTER_LINKS_SECTION)
    .addClientAPIEvent(INJECT_BUTTON_TO_MENU)
    .addClientAPIEvent(GET_PALETTE_DATA)
    .addClientAPIEvent(INJECT_ACCESSIBILITY_ICON_TO_HEADER)
    .addClientAPIEvent(INJECT_ADS)
    .build();
