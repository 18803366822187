import React from 'react';
import {
  Ad,
  AppContent,
  baseUnit,
  ErrorBoundary,
  MEDIA_BREAKPOINTS,
  OneVCNineHLC,
  FourVCTwoLines,
  SectionWithTitle,
  SectionHeader,
  SecondarySectionTitle,
  WideScrollableArticleList,
  MEDIUM_SCREEN_SIZE,
  SMALL_SCREEN_SIZE,
} from 'mm-ui-components';
import { Store } from 'redux';
import { useSelector } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { Page } from '../Page';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import {
  getMetadataDescription,
  getMetadataImage,
  getMetadataTitle,
  getSectionArticlesByKey,
  getSectionTitleByKey,
  getHreflangData,
  getMoreButtonTextByKey,
  getMoreButtonSvgByKey,
} from '../../store/template/homePage/homePageFlorence/homePageFlorence.selectors';

import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getUseFormatMinutesHoursAgo,
} from '../../store/config/config.selectors';
import {
  FROM_DYNAMIC_SECTION_INDEX,
  HomePageFlorenceMinorSections,
  SectionNames,
  TO_DYNAMIC_SECTION_INDEX,
} from '../../store/template/homePage/homePageFlorence/homePageFlorence.initialState';
import { Article } from '../../store/template/template.state.types';
import { AdditionalStyleSheet } from '../templates.utils';
import { createSIMetaImage } from '../../store/template/template.utils';

interface HomePageProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesFromDynamicSections = (state: AppState) => {
  let articles: Article[] = [];
for (let i = FROM_DYNAMIC_SECTION_INDEX; i <= TO_DYNAMIC_SECTION_INDEX; i += 1) {
  const sectionName = `section${i}` as SectionNames;
  const sectionArticles = getSectionArticlesByKey(sectionName)(state) || [];
  if (sectionArticles.length) {
    articles = [...articles, ...sectionArticles];
  }
}
return articles;
};

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const contentSection1Articles = getSectionArticlesByKey(SECTION_NAMES.SECTION1)(state) || [];
  const articlesFromDynamicSections = getArticlesFromDynamicSections(state);

  return [...topSectionArticles, ...contentSection1Articles, ...articlesFromDynamicSections];
};

export const HomePageFlorenceComponent: React.FunctionComponent<
  HomePageProps
> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <HomePageFlorenceContent />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    gridRowGap: `${baseUnit * 2}px`,
    [MEDIA_BREAKPOINTS.medium]: {
      maxWidth: `${MEDIUM_SCREEN_SIZE}px`,
      margin: '0 auto',
    },
    [MEDIA_BREAKPOINTS.small]: {
      maxWidth: `${SMALL_SCREEN_SIZE}px`,
      margin: '0 auto',
    },
    display: 'grid',
    gridTemplateColumns: '1fr',
    marginTop: `${baseUnit}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
});

const sectionStyle = StyleSheet.create({
  style: {
    [MEDIA_BREAKPOINTS.medium]: {
      padding: `0 ${baseUnit}px`,
    },
  },
}) as AdditionalStyleSheet;

interface DynamicSectionWrapperProps {
  sectionName: SectionNames;
}

const DynamicSectionWrapper = ({
  sectionName,
}: DynamicSectionWrapperProps) => {
  const sectionArticles = useSelector(getSectionArticlesByKey(sectionName as SectionNames));
  const sectionTitle = useSelector(getSectionTitleByKey(sectionName));
  const sectionMoreButtonText = useSelector(
    getMoreButtonTextByKey(sectionName as HomePageFlorenceMinorSections),
  );
  const sectionMoreButtonSvg = useSelector(
    getMoreButtonSvgByKey(sectionName as HomePageFlorenceMinorSections),
  );
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);

  return sectionArticles?.length > 0 ? (
    <SectionWithTitle additionalStyle={sectionStyle}>
      <SectionHeader
        moreButtonSvg={sectionMoreButtonSvg}
        moreButtonText={sectionMoreButtonText}
      >
        <SecondarySectionTitle>{sectionTitle}</SecondarySectionTitle>
      </SectionHeader>
      <FourVCTwoLines
        cards={sectionArticles.slice(0, 8)}
        useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
      />
    </SectionWithTitle>
  ) : null;
};

export const HomePageFlorenceContent: React.FunctionComponent = () => {
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)) || [];
  const topSectionTitle = useSelector(
    getSectionTitleByKey(SECTION_NAMES.TOP_SECTION),
  );
  const contentSection1Articles = useSelector(getSectionArticlesByKey(SECTION_NAMES.SECTION1)) || [];
  const contentSection1Title = useSelector(
    getSectionTitleByKey(SECTION_NAMES.SECTION1),
  );
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const canonical = useSelector(getCanonicalUrl);
  let pageImage = useSelector(getMetadataImage);
  pageImage = createSIMetaImage(canonical, pageImage);
  const articles = useSelector(getArticlesInTemplate);
  const articlesFromDynamicSections = useSelector(getArticlesFromDynamicSections);
  const hreflangData = useSelector(getHreflangData) || [];
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));

  const belowTopSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowTopSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_TOP_SECTION));
  const belowSecondSectionAdId = useSelector(getAdSlotId(SLOT_NAMES.BELOW_SECOND_SECTION));
  const belowSecondSectionAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.BELOW_SECOND_SECTION));
  const oneOnOneAdId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const oneOnOneAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const style = createStyle();

  return (
    <React.Fragment>
      <MetaTags
        articles={articles}
        title={metaTitle}
        description={metaDescription}
        pageImage={pageImage}
        alternateUrls={hreflangData}
      />
      <HeadScripts />
      <Theme>
        <AppContent>
          <NavigationLayout mainWrapperConfigThemeKey="mainContent" noMarginTop isFullBleed>
            <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
            <div className={css(style.sectionsWrapper)}>
              <SectionWithTitle additionalStyle={sectionStyle}>
                <OneVCNineHLC
                  cards={topSectionArticles}
                  displayName={topSectionTitle}
                  useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                />
              </SectionWithTitle>
              {contentSection1Articles.length ? (
                <WideScrollableArticleList
                  cardsProps={contentSection1Articles.slice(0, 4)}
                  paddingFactors={{ large: 0.5, medium: 0.5, small: 0.5 }}
                  useFormatMinutesHoursAgo={useFormatMinutesHoursAgo}
                  cardsSectionDisplayName={contentSection1Title}
                />
                ) : null}
              <Ad id={belowTopSectionAdId} onSuccess={belowTopSectionAdOnSuccess} setMinHeight showInDesktop={false} showInTablet={false} />
              {Array.from({ length: 10 }, (_, i) => {
                const sectionName = `section${i + FROM_DYNAMIC_SECTION_INDEX}` as SectionNames;
                return (
                  <>
                    <DynamicSectionWrapper
                      key={sectionName}
                      sectionName={sectionName as HomePageFlorenceMinorSections}
                    />
                    { i === 0 && articlesFromDynamicSections?.length > 0
                    && <Ad id={belowSecondSectionAdId} onSuccess={belowSecondSectionAdOnSuccess} setMinHeight />}
                    { i === 4
                    && <Ad id={oneOnOneAdId} onSuccess={oneOnOneAdOnSuccess} setMinHeight showInMobile={false} />}
                  </>
                );
              })}
            </div>
          </NavigationLayout>
        </AppContent>
      </Theme>
    </React.Fragment>
  );
};
